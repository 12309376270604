import React, { useState } from 'react';
import { Container, TextField, Button, Typography, Box } from '@mui/material';
import { makeStyles } from "@mui/styles";
import axios from "axios";


const useStyles = makeStyles({
    input: {
        color: "white !important"
    },
    root: {
        background: "black"
    },
});

function Home() {
    const [email, setEmail] = useState('');
    const [emailError, setEmailError] = useState('');
    const [submissionSuccess, setSubmissionSuccess] = useState(false);
    const [loading, setLoading] = useState(false); // Add this line

    const classes = useStyles();

    const handleEmailChange = (event) => {
        setEmail(event.target.value);
        // Clear error message when the user starts typing again
        if (emailError) setEmailError('');
    };

    const validateEmail = (email) => {
        return /\S+@\S+\.\S+/.test(email);
    };

    const handleSubmit = async (event) => {
        event.preventDefault();


        if (!validateEmail(email)) {
            setEmailError('Please enter a valid email address');
            return;
        }
        setLoading(true); // Start loading before the async operation
        // Implement the API call here. This is just a placeholder.
        try {
            const response = await axios.post(
                "https://mail-scanbo.vercel.app/send-email-dequity-comingSoon",
                { email }
            );
            console.log("Email sent successfully", response.data);
            setSubmissionSuccess(true);
            setEmail('');
            setLoading(false); // Stop loading after the operation is successful
            // Optionally reset form or set success message
        } catch (error) {
            console.error("Error sending email", error);
            setEmailError('Failed to send email. Please try again.'); // Set error message if the submission fails

            setLoading(false); // Stop loading if there is an error
        }
    };

    return (
        <Container maxWidth="sm" style={{ marginTop: '50px', textAlign: 'center' }}>
            <Box marginBottom={4} style={{

                boxShadow: "rgb(255 255 255 / 17%) 0px 18px 50px -10px"
            }}>
                {/* Replace the src attribute with the path to your company logo */}
                <img src="/DequiteyTechnology.jpg" alt="Company Logo" style={{ maxWidth: '100%', height: 'auto' }} />
            </Box>
            <Typography variant="h4" gutterBottom color="white">
                We're Launching Soon
            </Typography>


            {/* <label style={{ color: "white", textAlign: "left" }}>Enter Email</label> */}
            <form onSubmit={handleSubmit}>
                <Box>
                    {submissionSuccess ?
                        <Typography variant="h6" gutterBottom color="White">
                            <Box className={classes.thankYou}>
                                Thank you! Your submission has been received!
                            </Box>
                        </Typography>

                        : (

                            <>
                                <TextField
                                    fullWidth
                                    label="Enter your email"
                                    variant="outlined"
                                    className={classes.root}
                                    value={email}
                                    onChange={handleEmailChange}
                                    margin="normal"
                                    error={!!emailError}
                                    helperText={emailError}
                                    InputLabelProps={{
                                        style: { color: '#fff' }, // Makes the label text white
                                    }}
                                    InputProps={{
                                        className: classes.input,
                                        style: { color: '#fff' }, // Makes the input text white
                                    }}
                                    sx={{
                                        '& .MuiOutlinedInput-root': {
                                            '& fieldset': {
                                                borderColor: 'white',
                                            },
                                            '&:hover fieldset': {
                                                borderColor: 'lightgray',
                                            },
                                            '&.Mui-focused fieldset': {
                                                borderColor: 'white',
                                            }
                                        },
                                    }}
                                />
                                <Button
                                    type="submit"
                                    variant="contained"
                                    color="primary"
                                    style={{ background: "#6b38f7", marginTop: "10px" }}
                                //   disabled={loading || !validateEmail(email)}
                                >
                                    {loading ? "Please wait..." : "Subscribe Email"}
                                </Button>
                            </>
                        )
                    }

                </Box>


            </form>
        </Container>
    );
}

export default Home;

