import React from 'react';
import './App.css';
import { BrowserRouter as Router, Routes, Route, useLocation } from 'react-router-dom';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { Helmet } from 'react-helmet';
import Home from './HomePage/Home';

const theme = createTheme({

  // ... your other theme overrides
});

function App() {
  return (
    <>
      <Helmet>
        <meta name="google-site-verification" content="google96dc4a127ddefcb2" />
      </Helmet>
      <ThemeProvider theme={theme}>
        <Router>
          <div className="App">
            <main>
              <Routes>
                <Route path="/" element={<Home />} />
              </Routes>
            </main>
          </div>
        </Router>
      </ThemeProvider>
    </>
  );
}

export default App;
